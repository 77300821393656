import React, { useEffect } from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import CTA from '../components/common/CTA'
import Layout from '../components/common/Layout'
import Page from '../components/formats/Page'

export const SingleFileFormat = ({ data }) => {
  const title = data.page.frontmatter.title
  const excerpt = data.page.frontmatter.excerpt
  const short = data.page.frontmatter.short
  const body = data.page.html
  const meta = data.page.frontmatter.meta
  const formats = data.formats.edges.flatMap(({ node }) => node.frontmatter)

  return (
    <Layout meta={meta}>
      <main>
        <Page title={title} excerpt={excerpt} short={short} body={body} />
        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default SingleFileFormat

export const pageQuery = graphql`
  ## Query for SingleFileFormat data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleFileFormat($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        excerpt
        short
        featuredImage
        meta {
          title
          description
          absoluteImageUrl
        }
      }
    }

    formats: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "formats" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            excerpt
            featuredImage
          }
        }
      }
    }
  }
`
