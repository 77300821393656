import React, { useState, useEffect } from 'react'
import styles from './Page.module.scss'
import Content from '../singlePost/Content'
import { Link } from 'gatsby'

const Page = ({ title, excerpt, short, body }) => {
  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div>
            <div>
              <div className={styles.headerContent}>
                <div className={styles.heading}>
                  <div className={styles.breadcrumb}>
                    <Link to="/formats/"> Formats</Link> /{' '}
                    <Link
                      to={`/formats/${title
                        .toLowerCase()
                        .replace(/\s+/g, '-')}/`}
                    >
                      {title}
                    </Link>
                  </div>
                </div>
              </div>
              <div className={styles.articleContent}>
                <article itemScope itemType="http://schema.org/BlogPosting">
                  <span className={styles.tag}>{short}</span>
                  <h1>{title}</h1>
                  <p>{excerpt}</p>
                  <Content source={body} />
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Page
